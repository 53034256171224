
import { useActivityLogMaintenanceTracked } from "presentation/store/ActivityLog/ActivityLogMaintenanceProvider";
import { IconButton, SliderPanel } from "veronica-ui-component/dist/component/core";
import { ActivityLogSearchPanel } from "./ActivityLogSearchPanel";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { ActivityLogMaintenanceConstant } from "presentation/constant/ActivityLog/ActivityLogMaintenanceConstant";
import { useCallback } from "react";
import ActivityLogTablePanel from "./ActivityLogTablePanel";
import { useActivityLogMaintenanceVM } from "presentation/hook/ActivityLog/useActivityLogMaintenanceVM";

export const ActivityLogMasterView: React.FC = () => {
    const [activityLogState] = useActivityLogMaintenanceTracked();
    const { isShowCriteriaPanel } = activityLogState;
    const activityLogVM = useActivityLogMaintenanceVM();

    const handleSearchClick = useCallback(() => {
        activityLogVM.onToggleCriteriaPanel();
    }, [activityLogVM]);
    
    return <>
        <div className='main-comp-wrapper'>
                <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
                    <HeaderTitle>
                        {ActivityLogMaintenanceConstant.TITLE}
                    </HeaderTitle>
                    <StyledAction className="tm-animated-wrapper">
                        <IconButton fileName='Icon-search' disabled={false} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearchClick} />
                    </StyledAction>
                </Sidebarheader>
                <SliderPanel
                    isOpen={true}
                    leftSectionWidth={isShowCriteriaPanel?'22%':'0%'}
                    rightSectionWidth={isShowCriteriaPanel?'78%':'100%'}
                    draggable={false}
                    leftChildren={<ActivityLogSearchPanel/>}
                    rightChildren={<ActivityLogTablePanel/>} />
            </div>
    </>
}