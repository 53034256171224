import { ActivityLogMaintenanceConstant } from "./ActivityLogMaintenanceConstant";

const SCREEN_CONSTANT = ActivityLogMaintenanceConstant;
export const INITIAL_ACTIVITY_LOG_COL_DEF: any[] = [
        {
            headerName: SCREEN_CONSTANT.LOG_DATE_TIME,
            field: "createdDateTime",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 170,
            dataType: "dateTime"
            
        },
        {
            headerName: SCREEN_CONSTANT.LOGGED_BY,
            field: "createdBy",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
        },
        {
            headerName: SCREEN_CONSTANT.PROCESS_ID,
            field: "processId",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 500,
        },
        {
            headerName: SCREEN_CONSTANT.OPERATING_COMPANY,
            field: "operatingCompany",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
        },
        {
            headerName: SCREEN_CONSTANT.OPERATING_UNIT,
            field: "operatingUnit",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
        },
        {
            headerName: SCREEN_CONSTANT.MESSAGE,
            field: "message",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 400,
        }
]
