import { INITIAL_ACTIVITY_LOG_COL_DEF } from "presentation/constant/ActivityLog/ActivityLogMaintenanceColumnDefinition";
import { useActivityLogMaintenanceTracked } from "presentation/store/ActivityLog/ActivityLogMaintenanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useMemo, useRef } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";

const ActivityLogTablePanel:React.FC = () => {
    const [activityLogState] = useActivityLogMaintenanceTracked();
    const gridRef: any = useRef(null);
    const {tableData} = activityLogState;

    const memoTable = useMemo(() => {
        return (
        <HPHTable
            id='activity-log-table'
            isNewColumnSetting={true}
            columns={INITIAL_ACTIVITY_LOG_COL_DEF}
            data={tableData??[]}
            showPaginator={false}
            editable={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            isRowHighligted={true}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            ref={gridRef}
            />
        );
    },[tableData])

    return <><TableWrapper>
            {memoTable}
        </TableWrapper>
        </>;
}

export default memo(ActivityLogTablePanel);
